:root.testosterone-increase-guarantee-step {
  background-color: var(--color-black);
  color: var(--color-main-light);
}

.testosterone-increase-guarantee-step {
  &,
  & body,
  .maximus-page-header {
    background-color: var(--color-black);
    color: var(--color-main-light);
  }
}
