@import '../../../../css/colors';

.progress_tracker__upnext {
  &__progressbar {
    display: flex;
    align-items: center;

    .line {
      width: 80%;
      height: 8px;
      background-color: #ededf0;
      position: relative;
      margin-right: 13px;

      @media (max-width: 479px) {
        margin-right: 7px;
      }

      &__inner {
        position: absolute;
        background-color: #37833b;
        height: 8px;
      }
    }

    .value {
      @media (max-width: 479px) {
        font-size: 12px;
      }
    }

    &__flex {
      display: flex;
      gap: 48px;

      @media (max-width: 479px) {
        gap: 15px;
      }

      &.elation {
        @media (max-width: 479px) {
          gap: 20px;
          flex-direction: column;
        }
      }

      & > div {
        flex: 1 1 50%;
      }
    }
  }
}

.text12 {
  font-size: 12px;
  font-weight: 300;
}

.maintain {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  & > div:first-child {
    color: #444;
    font-size: 16px;
    font-weight: 800;
  }

  & > div:last-child {
    height: 1px;
    flex: 1 0 0;
    background: #444;
  }
}
