@import '../../../css/colors';

.referral-source-modal.billing-issue {
  .modal-content {
    .modal-body {
      position: revert;
    }
  }
  .referral-source-modal {
    &__content {
      width: 100%;
      font-size: 14px;
      line-height: 1.4;

      a {
        color: var(--primary-color);
        font-weight: 600;
      }

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      ul {
        li {
          margin-bottom: 15px;
          padding-left: 16px;
          position: relative;

          &:before {
            content: '';
            background: var(--primary-lighten-color);
            width: 6px;
            height: 6px;
            border-radius: 3px;
            position: absolute;
            left: 0px;
            top: 6px;
          }
        }
      }
    }
  }

  .billing-issue-message {
    font-weight: 600;
  }

  .billing-issue-link {
    cursor: pointer;
    color: var(--primary-color);
    font-weight: 600;
    text-decoration: underline;
  }

  .bp5-icon.bp5-icon-cross svg {
    fill: #c7c7c7 !important;
  }
}
