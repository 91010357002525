@import 'colors';
@import 'variables';
@import 'breakpoints';

.registration-font-1 {
  font-family: Cambon;
  font-weight: 600;
  font-size: 48px;
  line-height: 57.6px;
}
.registration-font-2 {
  font-family: OpenSans;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(237, 237, 237, 0.65);
}
@media (max-width: 479px) {
  .fScience {
    font-family: OpenSans;
    font-size: 14px;
    color: #cbcbcb;
    line-height: 18.48px;
  }
}
@media (min-width: 480px) {
  .fScience {
    margin-top: 12px;
    font-family: OpenSans;
    font-weight: 100;
    font-size: 20px;
    color: #cbcbcb;
    line-height: 30px;
  }
}
.footer-font {
  font-family: Lato;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $gray_3;
  text-transform: uppercase;
}
.blue-small-subheading {
  font-family: Lato;
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  letter-spacing: 0.08em;
  color: var(--primary-color);
  text-transform: uppercase;
}

@mixin heading-large {
  font-family: Cambon;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.04em;
  @include breakpoint(sm) {
    font-size: 64px;
    line-height: 120%;
  }
}
.heading-large {
  @include heading-large;
}

@mixin heading-medium {
  font-family: Cambon;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 116%;
  letter-spacing: 0.04em;
}
.heading-medium {
  @include heading-medium;
}

@mixin heading-small {
  font-family: Cambon;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.04em;
}
.heading-small {
  @include heading-small;
}
@media (max-width: 479px) {
  .heading-medium-large {
    @include heading-medium;
  }
}
@media (min-width: 480px) {
  .heading-medium-large {
    @include heading-large;
  }
}

@mixin subheading-large {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 132%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  @include breakpoint(sm) {
    font-size: 16px;
  }
}
.subheading-large {
  @include subheading-large;
}

@mixin subheading-small {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 132%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
}
.subheading-small {
  @include subheading-small;
}

@mixin label-normal-extra-large {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
}
.label-normal-extra-large {
  @include label-normal-extra-large;
}
@mixin body-normal-extra-large {
  @include label-normal-extra-large;
  line-height: 132%;
}
.body-normal-extra-large {
  @include body-normal-extra-large;
}

@mixin label-normal-large {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
}
.label-normal-large {
  @include label-normal-large;
}
@mixin body-normal-large {
  @include label-normal-large;
  line-height: 126%;
}
.body-normal-large {
  @include body-normal-large;
}

@mixin label-normal-medium {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
}
.label-normal-medium {
  @include label-normal-medium;
}
@mixin body-normal-medium {
  @include label-normal-medium;
  line-height: 132%;
  font-feature-settings: 'ss01' on;
}
.body-normal-medium {
  @include body-normal-medium;
}

@mixin label-normal-small {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss01' on;
  line-height: 100%;
}
.label-normal-small {
  @include label-normal-small;
}
@mixin body-normal-small {
  @include label-normal-small;
  line-height: 140%;
}
.body-normal-small {
  @include body-normal-small;
}

@mixin label-normal-extra-small {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss01' on;
  line-height: 100%;
}
.label-normal-extra-small {
  @include label-normal-extra-small;
}
@mixin body-normal-extra-small {
  @include label-normal-extra-small;
  line-height: 140%;
}
.body-normal-extra-small {
  @include body-normal-extra-small;
}

@mixin label-light-extra-large {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 100%;
}
.label-light-extra-large {
  @include label-light-extra-large;
}
@mixin body-light-extra-large {
  @include label-light-extra-large;
  & {
    line-height: 132%;
  }
}
.body-light-extra-large {
  @include body-light-extra-large;
}

@mixin label-light-large {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 100%;
}
.label-light-large {
  @include label-light-large;
}
@mixin body-light-large {
  @include label-light-large;
  line-height: 126%;
}
.body-light-large {
  @include body-light-large;
}

@mixin label-light-medium {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  font-feature-settings: 'ss01' on;
  line-height: 100%;
}
.label-light-medium {
  @include label-light-medium;
}
@mixin body-light-medium {
  @include label-light-medium;
  line-height: 132%;
}
.body-light-medium {
  @include body-light-medium;
}

@mixin label-light-small {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss01' on;
  line-height: 100%;
}
.label-light-small {
  @include label-light-small;
}
@mixin body-light-small {
  @include label-light-small;
  line-height: 140%;
}
.body-light-small {
  @include body-light-small;
}

@mixin label-light-extra-small {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss01' on;
  line-height: 100%;
}
.label-light-extra-small {
  @include label-light-extra-small;
}
@mixin body-light-extra-small {
  @include label-light-extra-small;
  line-height: 140%;
}
.body-light-extra-small {
  @include body-light-extra-small;
}

@mixin f-button-small {
  text-transform: uppercase;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 116%;
  letter-spacing: 0.08em;
  font-feature-settings: 'ss01' on;
}
.f-button-small {
  @include f-button-small;
}

@mixin f-button-medium {
  text-transform: uppercase;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.08em;
  font-feature-settings: 'ss01' on;
}
.f-button-medium {
  @include f-button-medium;
}

@mixin f-button-large {
  text-transform: uppercase;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 116%;
  letter-spacing: 0.08em;
  font-feature-settings: 'ss01' on;
}
.f-button-large {
  @include f-button-large;
}

@mixin f-caption {
  font-family: OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.06em;
}

@mixin roboto-small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  @include breakpoint(sm) {
    font-size: 18px;
  }
}
.roboto-small {
  @include roboto-small;

  &.-gray {
    color: $gray_7;
  }
}
@mixin paragraph-light-normal {
  font-family: Univers;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  @include breakpoint(xl) {
    font-size: 24px;
    line-height: 126%;
  }
}
.paragraph-light-normal {
  @include paragraph-light-normal;

  &.-gray-brown-transparent {
    color: rgba(17, 0, 0, 0.67);
  }
  &.-gray-brown {
    color: $gray_4;
  }
}

.paragraph-subheader {
  @include paragraph-light-normal;
  & {
    font-size: 24px;
  }
  @include breakpoint(sm) {
    font-size: 32px;
  }
}

.f-caption {
  @include f-caption;
}

.-uppercase {
  text-transform: uppercase;
}
