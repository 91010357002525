@import 'variables';
@import 'breakpoints';
@import 'grid';
@import 'nudge';
@import 'utils';
@import 'font_faces';
@import 'fonts';
@import 'animate';

@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin subheader {
  display: block;
  font-size: 75%;
  opacity: 0.75;
}
html {
  overscroll-behavior: auto !important;
}
.body {
  height: 100%;
  touch-action: manipulation;
}
.admin-grid-container #sidebar {
  width: 1000px;
}
.admin-grid-container .header__content {
  min-width: 100%;
}
#sidebar {
  z-index: 3;
  position: fixed; /* or choose `absolute` depending on desired behavior*/
  top: 0;
  bottom: 0;
  width: 350px;
  transition: transform 0.3s ease-in-out;
}
#sidebar.collapsed {
  transform: translateX(0%);
  left: -1000px;
}
:not(.select__menu-list, .admin-menu_popup-menu, .dropdown-fixed) {
  &::-webkit-scrollbar {
    width: 0;
  }
}
ul {
  list-style-type: none;
}
[data-react-class='App'] {
  height: 100%;
  width: 100%;
}
.trigger_test_error {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.apart {
  display: flex;
  > div:first-child {
    flex: 1;
  }
}
