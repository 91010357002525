ul.modal-list {
  margin-top: 0;

  li {
    margin-bottom: 0;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 59px;
  background: #fff;
  padding: 32px 20px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  text-align: center;
  left: 0;
  right: 0;

  .new-theme & {
    bottom: 0;
  }

  a {
    font-size: 14px;
    text-decoration: underline;
  }

  .default-button {
    max-width: 400px;
  }

  @media (max-width: 600px) {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.new-theme .fixed-footer {
  a {
    text-decoration: none;
  }
}
